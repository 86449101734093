import { navigate } from "gatsby";
import React, { CSSProperties, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
// import { urlService } from '@/_services';
import { addError } from "../utils/errors";
import { urlService } from "../_services/urlService";
import WaitingAnimation from "./WaitingAnimation";

const dropZoneStyle: CSSProperties = {
  width: "100%",
  height: "60px",
  backgroundColor: "white",
  border: "4px dashed lightblue",
  textAlign: "center",
};

function MyDropzone() {
  let [is_waiting, setIsWaiting] = useState(false);

  const onDrop = useCallback((acceptedFiles: any) => {
    setIsWaiting(true);
    var formData = new FormData();
    formData.append("image", acceptedFiles[0]);

    let url = urlService.getUrl("/api/drop");

    urlService
      .getAxios()
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        navigate(`/filecontent/${res.data.id}`, {});
      })
      .catch((err) => {
        setIsWaiting(false);
        if (err.response && err.response.status === 504) {
          addError(
            `Fehler beim Upload: Der Server konnte nicht erreicht werden. Bitte prüfen Sie Ihre Internetverbindung oder versuchen Sie es später noch einmal.`
          );
        } else {
          const errorMessage = err.response.data.displayMessage || "Unbekannter Fehler";
          addError(`Fehler beim Upload: ${errorMessage}`);
        }
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div style={{ padding: "0px 20px" }}>
      {is_waiting ? (
        <WaitingAnimation />
      ) : (
        <div {...getRootProps()} style={dropZoneStyle}>
          <input {...getInputProps()} />
          {isDragActive ? <p>Drop the files here ...</p> : <p>Ziehen Sie eine Datei hier rein, oder klicken Sie zum Auswählen der Datei</p>}
        </div>
      )}
    </div>
  );
}

export default function Upload() {
  return (
    <div className="py-4">
      <MyDropzone />
    </div>
  );
}
